import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "@components/layout";

// Hooks Querys & Context
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line
import { useTracking } from "@lib/tracking";

import { PageMeta } from "@components/pageMeta";
import ModuleZone from "~moduleZone";

const ModularPage = ({ data: { page } }) => {
  const { pageModules, config, seo, title } = page || {};
  const { track } = useTracking();

  useEffect(() => {
    track("Viewed Page", {
      page: title,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout {...config}>
      <PageMeta {...seo} />
      {pageModules && (
        <ModuleZone {...pageModules} reversed={config.reverseText} />
      )}
    </Layout>
  );
};

export default ModularPage;

export const pageQuery = graphql`
  query modularPageQuery($slug: String!) {
    page: sanityModularPage(slug: { current: { eq: $slug } }) {
      title
      seo {
        ...SEO
      }
      config {
        ...PageSettings
      }
      pageModules: modules {
        ...PageModules
      }
    }
  }
`;
